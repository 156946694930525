<template>
    <div class="referral-tier">
        <v-row no-gutters>
        <v-col no-gutters>
            <v-col cols=12 :md="3">
                <div class="title tier-container">{{$t(`label.referralTier`)}}</div>
            </v-col>
            <v-col cols=12 :md="3">
                <div class="tier-head pa-1 tier-container">
                    <img src="/static/image/referral/referral-people.svg" width="auto" height="75" alt="JAYA9-referral"/>
                </div>
                <v-card class="primary text-center tier-container">
                    <v-card-text class="pa-1">
                        <v-row no-gutters>
                            <v-col cols=12 no-gutters>
                                <label class="detail">{{ memberInfo.name || memberInfo.memberCode }}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols=12 :md="3" class="d-flex justify-center">
                <img class="tier-container" src="/static/image/referral/referral-arrow.svg" width="auto" height="16" alt="JAYA9-referral"/>
            </v-col>
            <v-col cols=12 :md="3">
                <div class="tier-head pa-1 tier-container">
                    <img src="/static/image/referral/referral-people.svg" width="auto" height="75" alt="JAYA9-referral"/>
                    <v-tooltip right >
                        <template v-slot:activator="{ on, attrs }">
                            <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg" width="auto" height="25" alt="JAYA9-referral"/>
                        </template>
                        <v-row no-gutters class="tier-tool-tip">
                            <v-col cols=12>
                                {{stringFormat($t(`label.tier`), 3)}}
                            </v-col>
                            <v-col cols=12>
                                {{stringFormat($t(`label.commissionTips`), getConfig(1))}}
                            </v-col>
                        </v-row>
                    </v-tooltip>
                </div>
                <v-card class="primary text-center tier-container">
                    <v-card-text class="pa-1">
                        <v-row no-gutters>
                            <v-col cols=12 no-gutters>
                                <label class="detail">{{stringFormat($t(`label.downlinesCommission`), getConfig(1))}}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols=12 :md="3" class="d-flex justify-center">
                <img class="tier-container" src="/static/image/referral/referral-arrow.svg" width="auto" height="16" alt="JAYA9-referral"/>
            </v-col>
            <v-col cols=12 :md="3">
                <div class="tier-head pa-1 tier-container">
                    <img src="/static/image/referral/referral-people.svg" width="auto" height="75" alt="JAYA9-referral"/>
                    <v-tooltip right >
                        <template v-slot:activator="{ on, attrs }">
                            <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg" width="auto" height="25" alt="JAYA9-referral"/>
                        </template>
                        <v-row no-gutters class="tier-tool-tip">
                            <v-col cols=12>
                                {{stringFormat($t(`label.tier`), 2)}}
                            </v-col>
                            <v-col cols=12>
                                {{stringFormat($t(`label.commissionTips`), getConfig(2))}}
                            </v-col>
                        </v-row>
                    </v-tooltip>
                </div>
                <v-card class="primary text-center tier-container">
                    <v-card-text class="pa-1">
                        <v-row no-gutters>
                            <v-col cols=12 no-gutters>
                                <label class="detail">{{stringFormat($t(`label.downlinesCommission`), getConfig(2))}}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols=12 :md="3" class="d-flex justify-center">
                <img class="tier-container" src="/static/image/referral/referral-arrow.svg" width="auto" height="16" alt="JAYA9-referral"/>
            </v-col>
            <v-col cols=12 :md="3">
                <div class="tier-head pa-1 tier-container">
                    <img src="/static/image/referral/referral-people.svg" width="auto" height="75" alt="JAYA9-referral"/>
                    <v-tooltip right >
                        <template v-slot:activator="{ on, attrs }">
                            <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg" width="auto" height="25" alt="JAYA9-referral"/>
                        </template>
                        <v-row no-gutters class="tier-tool-tip">
                            <v-col cols=12>
                                {{stringFormat($t(`label.tier`), 1)}}
                            </v-col>
                            <v-col cols=12>
                                {{stringFormat($t(`label.commissionTips`), getConfig(3))}}
                            </v-col>
                        </v-row>
                    </v-tooltip>
                </div>
                <v-card class="primary text-center tier-container">
                    <v-card-text class="pa-1">
                        <v-row no-gutters>
                            <v-col cols=12 no-gutters>
                                <label class="detail">{{stringFormat($t(`label.downlinesCommission`), getConfig(3))}}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-col>
        </v-row>
        <!-- <v-carousel class="mobile-carousel" v-model="currentMobileSlide" hide-delimiters v-else>
            <v-carousel-item>
                <v-sheet tile light>
                    <v-row no-gutters justify="center">
                        <v-col cols=8 sm=7>
                            <label>{{stringFormat($t(`label.totalTierMember`), 1)}}</label>
                            <v-card class="primary yellow--text title text-center pa-1 mt-3"><v-icon class="yellow--text">person</v-icon> : {{referralOverview.downline_lv1}}</v-card>
                            <v-card class="detail-card base mt-3 pt-2 px-1">
                                <v-card-title class="title">{{stringFormat($t(`label.tier`), 1)}}</v-card-title>
                                <v-divider class="mx-3 primary"></v-divider>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols=12>
                                           <label class="detail">{{stringFormat($t(`label.downlinesCommission`), getConfig(1))}}</label>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
                <v-sheet tile light>
                    <v-row no-gutters justify="center">
                        <v-col cols=8 sm=7>
                            <label>{{stringFormat($t(`label.totalTierMember`), 2)}}</label>
                            <v-card class="primary yellow--text title text-center pa-1 mt-3"><v-icon class="yellow--text">person</v-icon> : {{referralOverview.downline_lv2}}</v-card>
                            <v-card class="detail-card base mt-3 pt-2 px-1">
                                <v-card-title class="title">{{stringFormat($t(`label.tier`), 2)}}</v-card-title>
                                <v-divider class="mx-3 primary"></v-divider>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols=12>
                                             <label class="detail">{{stringFormat($t(`label.downlinesCommission`), getConfig(2))}}</label>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
                <v-sheet tile light>
                    <v-row no-gutters justify="center">
                        <v-col cols=8 sm=7>
                            <label>{{stringFormat($t(`label.totalTierMember`), 3)}}</label>
                            <v-card class="primary yellow--text title text-center pa-1 mt-3"><v-icon class="yellow--text">person</v-icon> : {{referralOverview.downline_lv3}}</v-card>
                            <v-card class="detail-card base mt-3 pt-2 px-1">
                                <v-card-title class="title">{{stringFormat($t(`label.tier`), 3)}}</v-card-title>
                                <v-divider class="mx-3 primary"></v-divider>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols=12>
                                            <label class="detail">{{stringFormat($t(`label.downlinesCommission`), getConfig(3))}}</label>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-carousel-item>
        </v-carousel> -->
    </div>
</template>

<script>
    import stringFormat from 'string-format'
    import {
        ROUTE_NAME
    } from '@/constants/route.constants'
    import {
        uiHelper,
        locale,
        errorCodeHelper,
        formValidator
    } from '@/util'
    import {
        SHARED
    } from '@/constants/constants'
    import deleteIcon from '@/assets/svg/delete-icon.svg'
    import _ from 'lodash';
    import {
        REFERRAL_GET_OVERVIEW,
        REFERRAL_GET_DOWNLINES,
        REFERRAL_GET_REPORT,
        REFERRAL_GET_CONFIG
    } from "@/store/referral.module";
    export default {
        name: 'referralTier',
        components: {
            deleteIcon
        },
        data: () => ({
            stringFormat: stringFormat,
            dateOfBirthMenu: false,
            language: uiHelper.getLanguage(),
            validator: formValidator,
            shared: SHARED,
            selectedTab: 'tier',
            dates: ['', ''],
            currentMobileSlide: 0
        }),
        created() {
            this.getReferralConfig()
            this.getReferralOverview()
            this.getReferralDownlines()
            this.getReferralReport()
        },
        computed: {
            dateRangeText() {
                let date1 = this.dates[0] ? this.dates[0] : ''
                let date2 = this.dates[1] ? this.dates[1] : ''
                return date1 + ' - ' + date2
            },
            isLoggedIn() {
                return this.$store.state.member.isLoggedIn
            },
            referralOverview() {
                return this.$store.state.referral.overview
            },
            referralConfig() {
                return this.$store.state.referral.config
            },
            memberInfo() {
                return this.$store.state.member.info
            },
        },
        watch: {},
        methods: {
            getConfig(index) {
                let config = this.referralConfig.find(c => c.level == index)
                if(config){
                    return config.percentage
                } else {
                    return 0
                }
            },
            openMsgDialog(selectedMsg) {
                this.msgDialog = {
                    show: true,
                    msg: selectedMsg.message,
                    title: selectedMsg.subject
                }
            },
            closeMsgDialog() {
                this.msgDialog = {
                    show: false
                }
            },
            getReferralConfig() {
                this.$store.dispatch(`${REFERRAL_GET_CONFIG}`)
            },
            getReferralOverview() {
                if (this.isLoggedIn)
                    this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
            },
            getReferralDownlines() {
                if (this.isLoggedIn)
                    this.$store.dispatch(`${REFERRAL_GET_DOWNLINES}`)
            },
            getReferralReport() {
                if (this.isLoggedIn)
                    this.$store.dispatch(`${REFERRAL_GET_REPORT}`)
            }
        }
    }
</script>

<style lang="scss">
    .referral-tier {
        .tier-head {
            position: relative;
            display: flex;
            justify-content: center;

            .tier-info {
                position: absolute;
                bottom: 8px;
                right: 0;
                cursor: pointer;
            }
        }
        .tier-container {
            margin: auto;
            max-width: 180px;
        }
        .tier-tool-tip {
            color: #ffffff;

            .tier-tool-tip-icon {
                background-color: #ffffff;
                mask: url(/static/image/referral/referral-info.svg);
                mask-repeat: no-repeat;
                mask-position: center;
                -webkit-mask: url(/static/image/referral/referral-info.svg);
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-position: center;
                cursor: pointer;
            }
        }
        .title {
            font-size: 18px !important;
        }
        .detail {
            color: white;
        }
        .mobile-carousel {
            height: 350px !important;
        }
    }
</style>