var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"referral-tier"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":3}},[_c('div',{staticClass:"title tier-container"},[_vm._v(_vm._s(_vm.$t("label.referralTier")))])]),_c('v-col',{attrs:{"cols":"12","md":3}},[_c('div',{staticClass:"tier-head pa-1 tier-container"},[_c('img',{attrs:{"src":"/static/image/referral/referral-people.svg","width":"auto","height":"75","alt":"JAYA9-referral"}})]),_c('v-card',{staticClass:"primary text-center tier-container"},[_c('v-card-text',{staticClass:"pa-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","no-gutters":""}},[_c('label',{staticClass:"detail"},[_vm._v(_vm._s(_vm.memberInfo.name || _vm.memberInfo.memberCode))])])],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":3}},[_c('img',{staticClass:"tier-container",attrs:{"src":"/static/image/referral/referral-arrow.svg","width":"auto","height":"16","alt":"JAYA9-referral"}})]),_c('v-col',{attrs:{"cols":"12","md":3}},[_c('div',{staticClass:"tier-head pa-1 tier-container"},[_c('img',{attrs:{"src":"/static/image/referral/referral-people.svg","width":"auto","height":"75","alt":"JAYA9-referral"}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"tier-info",attrs:{"src":"/static/image/referral/referral-info.svg","width":"auto","height":"25","alt":"JAYA9-referral"}},'img',attrs,false),on))]}}])},[_c('v-row',{staticClass:"tier-tool-tip",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("\n                            "+_vm._s(_vm.stringFormat(_vm.$t("label.tier"), 3))+"\n                        ")]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v("\n                            "+_vm._s(_vm.stringFormat(_vm.$t("label.commissionTips"), _vm.getConfig(1)))+"\n                        ")])],1)],1)],1),_c('v-card',{staticClass:"primary text-center tier-container"},[_c('v-card-text',{staticClass:"pa-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","no-gutters":""}},[_c('label',{staticClass:"detail"},[_vm._v(_vm._s(_vm.stringFormat(_vm.$t("label.downlinesCommission"), _vm.getConfig(1))))])])],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":3}},[_c('img',{staticClass:"tier-container",attrs:{"src":"/static/image/referral/referral-arrow.svg","width":"auto","height":"16","alt":"JAYA9-referral"}})]),_c('v-col',{attrs:{"cols":"12","md":3}},[_c('div',{staticClass:"tier-head pa-1 tier-container"},[_c('img',{attrs:{"src":"/static/image/referral/referral-people.svg","width":"auto","height":"75","alt":"JAYA9-referral"}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"tier-info",attrs:{"src":"/static/image/referral/referral-info.svg","width":"auto","height":"25","alt":"JAYA9-referral"}},'img',attrs,false),on))]}}])},[_c('v-row',{staticClass:"tier-tool-tip",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("\n                            "+_vm._s(_vm.stringFormat(_vm.$t("label.tier"), 2))+"\n                        ")]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v("\n                            "+_vm._s(_vm.stringFormat(_vm.$t("label.commissionTips"), _vm.getConfig(2)))+"\n                        ")])],1)],1)],1),_c('v-card',{staticClass:"primary text-center tier-container"},[_c('v-card-text',{staticClass:"pa-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","no-gutters":""}},[_c('label',{staticClass:"detail"},[_vm._v(_vm._s(_vm.stringFormat(_vm.$t("label.downlinesCommission"), _vm.getConfig(2))))])])],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":3}},[_c('img',{staticClass:"tier-container",attrs:{"src":"/static/image/referral/referral-arrow.svg","width":"auto","height":"16","alt":"JAYA9-referral"}})]),_c('v-col',{attrs:{"cols":"12","md":3}},[_c('div',{staticClass:"tier-head pa-1 tier-container"},[_c('img',{attrs:{"src":"/static/image/referral/referral-people.svg","width":"auto","height":"75","alt":"JAYA9-referral"}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"tier-info",attrs:{"src":"/static/image/referral/referral-info.svg","width":"auto","height":"25","alt":"JAYA9-referral"}},'img',attrs,false),on))]}}])},[_c('v-row',{staticClass:"tier-tool-tip",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("\n                            "+_vm._s(_vm.stringFormat(_vm.$t("label.tier"), 1))+"\n                        ")]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v("\n                            "+_vm._s(_vm.stringFormat(_vm.$t("label.commissionTips"), _vm.getConfig(3)))+"\n                        ")])],1)],1)],1),_c('v-card',{staticClass:"primary text-center tier-container"},[_c('v-card-text',{staticClass:"pa-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","no-gutters":""}},[_c('label',{staticClass:"detail"},[_vm._v(_vm._s(_vm.stringFormat(_vm.$t("label.downlinesCommission"), _vm.getConfig(3))))])])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }